import { gql } from '@apollo/client';

export type DeleteFileInput = {
  fileId: string;
};

export type DeleteFileResult = {
  deleteFile: {
    success: boolean;
    message: string;
  };
};

export const DELETE_FILE = gql`
  mutation deleteFile($fileId: ID!) {
    deleteFile(input: { fileId: $fileId }) {
      success
      message
    }
  }
`;
