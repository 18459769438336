import { Header } from 'semantic-ui-react';

const Billing = () => {
  return (
    <div>
      <Header as="h1">Billing</Header>
      <p>TODO</p>
    </div>
  );
};

export default Billing;
