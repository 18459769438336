import { useParams } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

const LoginButton = () => {
  const inviteCode = useParams().inviteCode!;
  console.log({ inviteCode });
  return <Menu.Item href={`${process.env.REACT_APP_SERVER_URL}/signup/${inviteCode}`}>Login</Menu.Item>;
};

export default LoginButton;
