import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const InviteFailure = () => {
  const [searchParams] = useSearchParams();
  const failureMessage = searchParams.get('finalize_fail');
  return <div>Failure during invitation process: {failureMessage}</div>;
};

export default InviteFailure;
