import { useParams } from 'react-router';

const AcceptInvite = () => {
  const inviteCode = useParams().inviteCode;
  console.log({ inviteCode });
  return (
    <div>
      <a href={`${process.env.REACT_APP_SERVER_URL}/invitation/signup/${inviteCode}`}>Accept Invitation</a>
    </div>
  );
};

export default AcceptInvite;
