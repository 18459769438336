import SharedByMe from './components/SharedByMe';
import SharedWithMe from './components/SharedWithMe';

const FileList = ({ tab }: { tab: 'sharedby' | 'sharedwith' }) => {
  if (tab === 'sharedby') {
    return <SharedByMe />;
  }
  return <SharedWithMe />;
};

export default FileList;
