import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Grid, Header, Loader, Message, Segment } from 'semantic-ui-react';
import {
  CreateDirectDownloadInput,
  CreateDirectDownloadResponse,
  CREATE_DIRECT_DOWNLOAD_REQUEST,
} from '../../../../../api/graphql/mutations/createDirectDownloadRequest';
import { GetAnymouseFileInfoResult, GET_ANONYMOUS_FILE_INFO } from '../../../../../api/graphql/queries/file';

const DirectDownload = () => {
  const [isBusy, setIsBusy] = useState(false);
  const fileId = useParams().fileId!;
  const { data, loading } = useQuery<GetAnymouseFileInfoResult>(GET_ANONYMOUS_FILE_INFO, {
    variables: { fileId },
  });
  // mutation to confirm email address and trigger an email with the download link
  const [directDownloadrequest] = useMutation<CreateDirectDownloadResponse, CreateDirectDownloadInput>(
    CREATE_DIRECT_DOWNLOAD_REQUEST,
    {
      onCompleted: (response: CreateDirectDownloadResponse) => {
        setIsBusy(false);
        if (response.directDownloadLink.success && response.directDownloadLink.downloadURL) {
          toast.success('Your download should now start');
          window.location.href = response.directDownloadLink.downloadURL;
        } else {
          toast.error(response.directDownloadLink.message);
        }
      },
    },
  );

  const doDownload = () => {
    setIsBusy(true);
    directDownloadrequest({ variables: { fileId } });
  };

  const fileInfo = data?.file;

  if (loading || isBusy) {
    return (
      <Grid textAlign="center" style={{ height: '100vh' }}>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="teal" textAlign="center">
            Download File
          </Header>
          <Segment>
            <Loader content={'Working.....'} inline active={loading || isBusy} size="small" />
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
  return (
    <Grid textAlign="center" style={{ height: '100vh' }}>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          Download File
        </Header>
        <Form size="large">
          <Segment>
            <p>
              You have been invited to download the file <b>{fileInfo?.name}</b> by <b>{fileInfo?.uploader}</b>
            </p>
            <Button color="teal" fluid size="large" onClick={doDownload}>
              Click here to download
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default DirectDownload;
