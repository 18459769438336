import { gql } from '@apollo/client';

/** A request to create a new file entry */
export type CreateDirectDownloadInput = {
  fileId: string;
};

/** A response to a file upload request */
export type CreateDirectDownloadResponse = {
  directDownloadLink: {
    /** Download URL */
    downloadURL?: string;
    /** Did it work? */
    success: boolean;
    /** Any relevant messages */
    message?: string;
  };
};

export const CREATE_DIRECT_DOWNLOAD_REQUEST = gql`
  mutation createDirectDownloadRequest($fileId: ID!) {
    directDownloadLink(fileId: $fileId) {
      success
      downloadURL
      message
    }
  }
`;
