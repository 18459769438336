import { Button, Container } from 'semantic-ui-react';
import NavBar from '../../components/navbar';

const UserAccessDenied = () => {
  return (
    <div>
      <NavBar />
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: 91 }}>
        <Container>
          <div className="ui message">
            <div className="header">User access denied</div>
            <p>You unable to log into SecureShare, please accept conditions upon next login attempt. </p>

            <Button primary href={`${process.env.REACT_APP_SERVER_URL}/login`}>
              Login
            </Button>
            <Button primary href={`${process.env.REACT_APP_SERVER_URL}/signup`}>
              Signup
            </Button>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default UserAccessDenied;
