import { useRef, useState } from 'react';
import { Form, FormField, Icon, Input, Label, Ref } from 'semantic-ui-react';
import EmailList from '../../EmailList';

interface Props {
  currentFile: File;
  newName?: string;
  setNewName: (newName: string) => void;
  emailAddresses: string[];
  setEmailAddresses: (emailAddress: string[]) => void;
}

const FileDetails = ({ currentFile, newName, setNewName, emailAddresses, setEmailAddresses }: Props) => {
  const updateName = (newName: string) => {
    setNewName(newName);
  };

  return (
    <div>
      <p>
        You are sharing "{currentFile.name}". Please add the list of email addresses that should have access to this
        file. You can change this list at any time - even after the file has been published.
      </p>
      <Form>
        <EmailList emails={emailAddresses} onChange={(emails) => setEmailAddresses(emails)} />
        <FormField inline>
          <label>What would you like to call the file?</label>
          <Input
            type="text"
            value={newName || currentFile.name}
            onChange={(event) => updateName(event.target.value)}
          ></Input>
        </FormField>
      </Form>
    </div>
  );
};

export default FileDetails;
