import { gql } from '@apollo/client';

export type UpdateAuthorisedEmailsInput = {
  authorisedEmails: string[];
  fileId: string;
};

export type UpdateAuthorisedEmailsResult = {
  updateAuthorisedEmails: {
    success: boolean;
    message: string;
  };
};

export const UPDATE_AUTHORISED_EMAILS = gql`
  mutation updateAuthorisedEmails($fileId: ID!, $authorisedEmails: [String]!) {
    updateAuthorisedEmails(input: { fileId: $fileId, authorisedEmails: $authorisedEmails }) {
      success
      message
    }
  }
`;
