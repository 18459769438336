import { gql } from '@apollo/client';

/** A request to create a new organisation */
export type CreateOrganisationInput = {
  /** The name of the organisation */
  name: string;
};

/** A response to a organisation Organisation request */
export type CreateOrganisationResponse = {
  createOrganisation: {
    /** Did it work? */
    success: boolean;
    /** Any relevant messages */
    message: string;
    /** The created organisation */
    organisation: {
      /** The id of the organisation */
      id: string;
      /** The name of the organisation */
      name: string;
    };
  };
};

export const CREATE_ORGANISATION = gql`
  mutation createOrganisation($name: String!) {
    createOrganisation(input: { name: $name }) {
      success
      message
      organisation {
        id
        name
      }
    }
  }
`;
