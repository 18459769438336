import { gql } from '@apollo/client';

export type AnonymousFileInfo = {
  id: string;
  /** The name of the user */
  name: string;
  /** Profile image */
  uploader: string;
};

export type GetAnymouseFileInfoResult = {
  file: AnonymousFileInfo;
};

export const GET_ANONYMOUS_FILE_INFO = gql`
  query GetAnymouseFileInfo($fileId: ID!) {
    file(id: $fileId) {
      id
      name
      uploader
    }
  }
`;

export type FileInfo = {
  id: string;
  name: string;
  magicLink: string;
  authorisedEmails: string[];
};

export type GetFileInfoResult = {
  file: FileInfo;
};

export const GET_FILE = gql`
  query GetFile($fileId: ID!) {
    file(id: $fileId) {
      id
      name
      magicLink
      authorisedEmails
    }
  }
`;

interface ISimpleFileInfo {
  id: string;
  name: string;
}

export interface IGetFilesResponse {
  files: ISimpleFileInfo[];
}

export const GET_FILES = gql`
  query GetFiles($organisationId: ID!) {
    files(organisationId: $organisationId) {
      id
      name
    }
  }
`;

export interface IGetShardWithMeFilesResponse {
  sharedWithMeFiles: ISimpleFileInfo[];
}

export const GET_SHARED_WITH_ME_FILES = gql`
  query GetSharedWithMeFiles {
    sharedWithMeFiles {
      id
      name
    }
  }
`;
