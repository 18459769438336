import { useQuery } from '@apollo/client';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { List } from 'semantic-ui-react';

import { GET_SHARED_WITH_ME_FILES, IGetShardWithMeFilesResponse } from '../../../../../../../api/graphql/queries/file';

const SharedWithMe = () => {
  const { pathname } = useLocation();

  let { organisationId } = matchPath('/organisations/:organisationId/shared_with_me', pathname)?.params || {};
  const { fileId, organisationId: organisationId2 } =
    matchPath('/organisations/:organisationId/shared_with_me/:fileId', pathname)?.params || {};

  organisationId = organisationId || organisationId2;

  const { data, loading } = useQuery<IGetShardWithMeFilesResponse>(GET_SHARED_WITH_ME_FILES);
  if (loading || !data) {
    return (
      <>
        <p>Loading...</p>
      </>
    );
  }
  return (
    <>
      {data.sharedWithMeFiles.length === 0 && <p>You don't have any files shared with you.</p>}
      <List divided>
        {data.sharedWithMeFiles.map((file) => (
          <List.Item
            style={{ padding: 10, backgroundColor: file.id == fileId ? '#F5F5F5' : 'inherit' }}
            as={Link}
            to={`/organisations/${organisationId}/shared_with_me/${file.id}`}
            key={file.id}
          >
            <List.Icon name="file" />
            <List.Content>
              <List.Header>{file.name}</List.Header>
            </List.Content>
          </List.Item>
        ))}
      </List>
    </>
  );
};

export default SharedWithMe;
