import { useMutation, useQuery } from '@apollo/client';
import { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Form, Header, Icon, Input, Table } from 'semantic-ui-react';
import {
  InviteUsersInput,
  InviteUsersResult,
  INVITE_USERS_TO_ORGANISATION,
} from '../../../../../../../../api/graphql/mutations/inviteUsersToOrganisation';
import {
  RemoveUserFromOrganisationInput,
  RemoveUserFromOrganisationResponse,
  REMOVE_USERS_FROM_ORGANISATION,
} from '../../../../../../../../api/graphql/mutations/removeUsersFromOrganisation';
import {
  RevokeInvitationInput,
  RevokeInvitationResponse,
  REVOKE_INVITATION,
} from '../../../../../../../../api/graphql/mutations/revokeInvitation';
import { User } from '../../../../../../../../api/graphql/queries/currentUser';
import {
  GET_INVITATIONS,
  InvitationsInput,
  InvitationsResult,
} from '../../../../../../../../api/graphql/queries/invitations';
import { GET_ORGANISATION, Organisation } from '../../../../../../../../api/graphql/queries/organisation';

const Members = ({ organisation, currentUser }: { organisation: Organisation; currentUser: User }) => {
  const [inviteEmail, setInviteEmail] = useState<string>();

  const [inviteUsers, { loading: invitingUser }] = useMutation<InviteUsersResult, InviteUsersInput>(
    INVITE_USERS_TO_ORGANISATION,
    {
      refetchQueries: [{ query: GET_INVITATIONS, variables: { organisationId: organisation.id } }],
      onCompleted: (data) => {
        if (data.inviteUsersToOrganisation.success) {
          toast.success('Invitation sent');
        } else {
          toast.error('Failed to invite users');
        }
      },
    },
  );

  const { data: pendingInvitations, loading: loadingInvitations } = useQuery<InvitationsResult, InvitationsInput>(
    GET_INVITATIONS,
    {
      variables: {
        organisationId: organisation.id,
      },
    },
  );

  const [removeUsers] = useMutation<RemoveUserFromOrganisationResponse, RemoveUserFromOrganisationInput>(
    REMOVE_USERS_FROM_ORGANISATION,
    {
      refetchQueries: [{ query: GET_ORGANISATION, variables: { organisationId: organisation.id } }],
      onCompleted: (data) => {
        if (data.removeUsersFromOrganisation.success) {
          toast.success('User removed');
        } else {
          toast.error('Failed to remove user');
        }
      },
    },
  );

  const [revokeInvite] = useMutation<RevokeInvitationResponse, RevokeInvitationInput>(REVOKE_INVITATION, {
    refetchQueries: [{ query: GET_INVITATIONS, variables: { organisationId: organisation.id } }],
    onCompleted: (data) => {
      if (data) {
        toast.success('Invitation revoked');
      } else {
        toast.error('Failed to revoke invitation');
      }
    },
  });

  const inviteUser = async () => {
    if (inviteEmail) {
      await inviteUsers({
        variables: {
          organisationId: organisation.id,
          owners: [],
          members: [inviteEmail],
        },
      });
    }
  };

  const revokeInvitation = (invitationCode: string) => {
    toast.loading('Revoking invitation', { toastId: 'revokingInvite' });
    revokeInvite({
      variables: {
        invitationCode,
      },
    });
    toast.dismiss('revokingInvite');
  };

  const removeUserFromOrg = async (id: string) => {
    toast.loading('Removing user', { toastId: 'removingUser' });
    await removeUsers({
      variables: {
        organisationId: organisation.id,
        userIds: [id],
      },
    });
    toast.dismiss('removingUser');
  };

  const changeRole = (id: string) => {
    console.log('Deleting member:', id);
    toast.success(`Deleted member: ${id}`);
  };

  return (
    <div>
      <Header as="h1">Invite someont to your organisation</Header>
      <Form onSubmit={() => inviteUser()}>
        <Form.Field>
          <Input
            action="Invite"
            fluid
            placeholder="Email address..."
            type="email"
            required
            onChange={(event: ChangeEvent<HTMLInputElement>) => setInviteEmail(event.target.value)}
            loading={invitingUser}
          />
        </Form.Field>
      </Form>
      {pendingInvitations && pendingInvitations.invitations.length > 0 && (
        <>
          <Header as="h1">Pending invites</Header>
          <Table basic="very" celled collapsing>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {pendingInvitations.invitations.map((invitation) => (
                <Table.Row>
                  <Table.Cell>{invitation.email}</Table.Cell>
                  <Table.Cell>
                    <Button icon color="red" onClick={() => revokeInvitation(invitation.code)}>
                      <Icon name="trash alternate outline" />
                      Delete
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      )}
      {organisation.users.length > 0 && (
        <>
          <Header as="h1">Members</Header>
          <Table basic="very" celled collapsing>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Role</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {organisation.users.map((user) => (
                <Table.Row>
                  <Table.Cell>{user.name}</Table.Cell>
                  <Table.Cell>{user.role}</Table.Cell>
                  <Table.Cell>
                    <Button
                      disabled={currentUser.id === user.userId}
                      icon
                      color="green"
                      onClick={() => changeRole(user.userId)}
                    >
                      Change Role
                    </Button>
                    <Button
                      disabled={currentUser.id === user.userId}
                      icon
                      color="red"
                      onClick={() => removeUserFromOrg(user.userId)}
                    >
                      <Icon name="trash alternate outline" />
                      Remove
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      )}
    </div>
  );
};

export default Members;
