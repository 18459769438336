import { gql } from '@apollo/client';

export type Invitation = {
  // email of the person being invited
  email: string;
  // the invite code
  code: string;
};

export type InvitationsResult = {
  invitations: Invitation[];
};

export type InvitationsInput = {
  organisationId: string;
};

export type GetInvites = {
  invitations: Invitation[];
};

export const GET_INVITATIONS = gql`
  query GetInvitations($organisationId: ID!) {
    invitations(organisationId: $organisationId) {
      email
      code
    }
  }
`;
