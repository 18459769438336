import Dropzone from 'react-dropzone';
import { Form, Segment } from 'semantic-ui-react';

const FileDrop = ({ currentFile, setCurrentFile }: { currentFile?: File; setCurrentFile: (newFile: File) => void }) => {
  return (
    <Segment>
      <Dropzone maxFiles={1} onDrop={(acceptedFiles) => setCurrentFile(acceptedFiles[0])}>
        {({ getRootProps, getInputProps }) => (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: 200,
            }}
          >
            <div
              style={{
                flex: 1,
                backgroundColor: 'white',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              {...getRootProps({ className: 'dropzone' })}
            >
              <input {...getInputProps()} />
              {currentFile ? <p>{currentFile.name}</p> : <p>Drag 'n' drop a file here, or click to select a file</p>}
            </div>
          </div>
        )}
      </Dropzone>
    </Segment>
  );
};

export default FileDrop;
