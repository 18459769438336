import { ToastContainer } from 'react-toastify';
import RequestDownload from './containers/RequestDownload';
import CompleteDownload from './containers/CompleteDownload';
import MainApp from './containers/MainApp';
import { Route, Routes } from 'react-router-dom';
import UserAccessDenied from './containers/UserAccessDenied';
import AcceptInvite from './components/AcceptInvite';
import CompleteInvite from './components/CompleteInvite';
import InviteFailure from './components/InviteFailure';

function App() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Routes>
        <Route path="/invitation/signup/:inviteCode" element={<AcceptInvite />} />
        <Route path="/invitation/finalize/:inviteCode" element={<CompleteInvite />} />
        <Route path="/invitation/failure" element={<InviteFailure />} />
        <Route path="/download/:fileId" element={<RequestDownload />} />
        <Route path="/download_do/:downloadId" element={<CompleteDownload />} />
        <Route path="/*" element={<MainApp />} />
        <Route path="/useraccessdenied" element={<UserAccessDenied />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
