import { gql } from '@apollo/client';

/** A request to create a new file entry */
export type CompleteUploadInput = {
  /** The name of the file */
  name: string;
  /** the content type of the file */
  contentType: string;
  /** The bucket the file is stored in */
  bucket: string;
  /** The key of the file */
  key: string;
  /** The authorised list of emails */
  authorisedEmails?: string[];
  /** Should we send an email to the authorised emails? */
  sendEmail?: boolean;
  /** The organisation that owns the file */
  organisationId: string;
};

/** A response to a file upload request */
export type CompleteUploadResponse = {
  completeFileUpload: {
    /** Did it work? */
    success: boolean;
    /** Any relevant messages */
    message?: string;
    /** The id of the file */
    fileId: string;
    /** The URL that can be shared to others */
    shareUrl?: string;
  };
};

export const COMPLETE_UPLOAD = gql`
  mutation completeUpload(
    $name: String!
    $contentType: String!
    $bucket: String!
    $key: String!
    $authorisedEmails: [String]!
    $sendEmail: Boolean
    $organisationId: ID!
  ) {
    completeFileUpload(
      input: {
        name: $name
        contentType: $contentType
        bucket: $bucket
        key: $key
        authorisedEmails: $authorisedEmails
        sendEmail: $sendEmail
        organisationId: $organisationId
      }
    ) {
      success
      message
      fileId
      shareUrl
    }
  }
`;
