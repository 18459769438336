import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid, Header, Icon, Input, Loader, Modal, Segment } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import {
  UpdateAuthorisedEmailsInput,
  UpdateAuthorisedEmailsResult,
  UPDATE_AUTHORISED_EMAILS,
} from '../../../../../../api/graphql/mutations/updateAuthorisedEmails';
import { GetFileInfoResult, GET_FILE } from '../../../../../../api/graphql/queries/file';
import EmailList from '../EmailList';
import { DeleteFileInput, DeleteFileResult, DELETE_FILE } from '../../../../../../api/graphql/mutations/deleteFile';

const FileDetails = () => {
  const navigate = useNavigate();

  const [updatingEmails, setUpdatingEmails] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deletingFile, setDeletingFile] = useState(false);

  const fileId = useParams().fileId!;

  const { data, loading, error } = useQuery<GetFileInfoResult>(GET_FILE, {
    variables: { fileId },
  });

  // update authorised emails mutation for file
  const [updateAuthorisedEmails] = useMutation<UpdateAuthorisedEmailsResult, UpdateAuthorisedEmailsInput>(
    UPDATE_AUTHORISED_EMAILS,
    {
      refetchQueries: ['GetFile'],
      onCompleted: (data) => {
        if (data?.updateAuthorisedEmails?.success) {
          toast.success('Authorised emails updated');
        } else {
          toast.error('Error updating authorised emails');
        }
        setUpdatingEmails(false);
      },
    },
  );

  // delete a file
  const [deleteFile] = useMutation<DeleteFileResult, DeleteFileInput>(DELETE_FILE, {
    refetchQueries: ['GetCurrentUser'],
    onCompleted: (data) => {
      console.log('Delete file', data);
      if (data.deleteFile.success) {
        toast.success('File deleted');
      } else {
        toast.error('Error deleting file');
      }
      setDeletingFile(false);
      navigate('/');
    },
  });

  const updateEmails = (authorisedEmails: string[]) => {
    setUpdatingEmails(true);
    updateAuthorisedEmails({
      variables: {
        fileId,
        authorisedEmails,
      },
    });
  };

  const copyToMagicLinkToClipboard = () => {
    const url = document.getElementById('magiclink');
    if (url?.textContent != null) {
      navigator.clipboard.writeText(url.textContent);
      toast.success('Copied to clipboard');
    }
  };

  const doDeleteFile = () => {
    setDeletingFile(true);
    deleteFile({
      variables: {
        fileId,
      },
    });
  };

  const fileInfo = data?.file;
  if (loading) {
    return (
      <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="top">
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="teal" textAlign="center">
            File Details
          </Header>
          <Segment>
            <Loader content={'Loading.....'} inline active={loading} size="small" />
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
  if (fileInfo) {
    return (
      <>
        <Header as="h2">{fileInfo.name}</Header>
        <Header as="h3">Sharable File Link:</Header>
        <Button onClick={() => copyToMagicLinkToClipboard()}>
          <span id="magiclink">{fileInfo.magicLink}</span> (Click to copy)
        </Button>
        <Header as="h3">Who has access to this file?</Header>
        {fileInfo.authorisedEmails.length > 0 ? (
          <p>This file is shared with the following people:</p>
        ) : (
          <p>This is file not sharead with anyone...</p>
        )}
        {updatingEmails ? (
          <Loader active inline size="small" />
        ) : (
          <EmailList emails={fileInfo.authorisedEmails} onChange={updateEmails} />
        )}
        <Header as="h3">Danger Zone</Header>
        <Button icon color="red" onClick={() => setDeleteConfirm(true)}>
          <Icon name="trash alternate outline" />
          Delete
        </Button>

        <Modal centered={false} open={deleteConfirm}>
          {deletingFile ? (
            <>
              <Modal.Header>Deleting File</Modal.Header>
              <Modal.Content>
                <Modal.Description>Your file is being deleted</Modal.Description>
              </Modal.Content>
            </>
          ) : (
            <>
              <Modal.Header>Delete File?</Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  Are you sure? This action cannot be undone.
                  <Button color="green" onClick={() => setDeleteConfirm(false)}>
                    No
                  </Button>
                  <Button icon color="red" onClick={() => doDeleteFile()}>
                    <Icon name="trash alternate outline" />
                    Yes
                  </Button>
                </Modal.Description>
              </Modal.Content>
            </>
          )}
        </Modal>
      </>
    );
  }
  return <div>Error</div>;
};

export default FileDetails;
