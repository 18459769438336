import { Dropdown, Image } from 'semantic-ui-react';
import { User } from '../../api/graphql/queries/currentUser';

const LogoutButton = ({ user }: { user: User }) => {
  return (
    <Dropdown
      item
      icon={<Image referrerPolicy="no-referrer" style={{ marginLeft: 10 }} src={user.picture} avatar />}
      text={user.name}
    >
      <Dropdown.Menu>
        <Dropdown.Item href={`${process.env.REACT_APP_SERVER_URL}/logout`}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LogoutButton;
