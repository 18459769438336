import { gql } from '@apollo/client';

export type RevokeInvitationInput = {
  invitationCode: string;
};

export type RevokeInvitationResponse = {
  revokeInvitation: boolean;
};

export const REVOKE_INVITATION = gql`
  mutation revokeInvitation($invitationCode: String!) {
    revokeInvitation(invitationCode: $invitationCode)
  }
`;
