import { useRef, useState } from 'react';
import { Form, Icon, Input, Label } from 'semantic-ui-react';

type EmailListProps = {
  emails: string[];
  onChange: (emails: string[]) => void;
};

const EmailList = ({ emails, onChange }: EmailListProps) => {
  const [email, setEmail] = useState<string>('');
  const emailInput = useRef<Input>(null);
  const inputSemantic = emailInput.current;
  const input = inputSemantic ? (inputSemantic as any).inputRef : null;

  const addEmail = () => {
    if (email && input?.current && input?.current.checkValidity()) {
      console.log('Adding email', email);
      onChange([...emails, email]);
      setEmail('');
    }
  };

  const removeEmail = (emailToRemove: string) => {
    const newEmailAddresses = emails.filter((email) => email !== emailToRemove);
    onChange(newEmailAddresses);
  };

  // add the email address when return is pressed
  const addEmailOnReturn = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      addEmail();
    }
  };

  return (
    <>
      {emails.map((email) => (
        <Label key={email}>
          {email}
          <Icon name="delete" onClick={() => removeEmail(email)} />
        </Label>
      ))}
      <Form.Field inline style={{ marginTop: 20 }}>
        <label>Add a new email address</label>
        <Input
          ref={emailInput}
          action={{ content: 'Add', onClick: () => addEmail() }}
          value={email}
          type="email"
          onChange={(event) => setEmail(event.target.value)}
          onKeyDown={addEmailOnReturn}
        ></Input>
      </Form.Field>
    </>
  );
};

export default EmailList;
