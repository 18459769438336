import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Form, FormField, Grid, Header, Input, Segment } from 'semantic-ui-react';
import {
  CreateOrganisationInput,
  CreateOrganisationResponse,
  CREATE_ORGANISATION,
} from '../../../../api/graphql/mutations/createOrganusation';

const CreateOrganisation = () => {
  const [name, setName] = useState('');
  const [isBusy, setIsBusy] = useState(false);
  const navigate = useNavigate();
  // mutation to create a new upload request - this gives us the signed upload URL for the file
  const [createOrganisation] = useMutation<CreateOrganisationResponse, CreateOrganisationInput>(CREATE_ORGANISATION, {
    refetchQueries: ['GetCurrentUser'],
    onCompleted: (response: CreateOrganisationResponse) => {
      setIsBusy(false);
      if (response.createOrganisation.success) {
        console.log('Organisation created successfully');
        navigate(`/organisations/${response.createOrganisation.organisation.id}/files/new`);
      } else {
        console.error('Error creating organisation');
      }
    },
  });

  // kick off the file upload process
  const create = () => {
    setIsBusy(true);
    createOrganisation({
      variables: {
        name: name,
      },
    });
  };

  return (
    <Container text style={{ marginTop: 80 }}>
      <Header as="h2">Create your organisation</Header>
      <Segment>
        <Form>
          <FormField>
            <label>Organisation name:</label>
            <Input
              disabled={isBusy}
              type="text"
              required
              value={name}
              onChange={(event) => setName(event.target.value)}
            ></Input>
          </FormField>
          <FormField inline>
            <Button disabled={isBusy || name.length === 0} onClick={() => create()}>
              Create Organisation
            </Button>
          </FormField>
        </Form>
      </Segment>
    </Container>
  );
};

export default CreateOrganisation;
