import { gql } from '@apollo/client';

/** A request to create a new file entry */
export type CreateDownloadInput = {
  fileId: string;
  email: string;
};

/** A response to a file upload request */
export type CreateDownloadResponse = {
  createDownloadRequest: {
    /** Did it work? */
    success: boolean;
    /** Any relevant messages */
    message?: string;
  };
};

export const CREATE_DOWNLOAD_REQUEST = gql`
  mutation createDownloadRequest($fileId: ID!, $email: String!) {
    createDownloadRequest(input: { fileId: $fileId, email: $email }) {
      success
      message
    }
  }
`;
