import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, Grid, Header, Input, Loader, Message, Segment } from 'semantic-ui-react';
import { GetDownloadLinkResult, GET_DOWNLOAD_LINK } from '../../api/graphql/queries/download';

const CompleteDownload = () => {
  const downloadId = useParams().downloadId;
  const { data, loading, error } = useQuery<GetDownloadLinkResult>(GET_DOWNLOAD_LINK, {
    variables: { downloadId },
  });
  const downloadLink = data?.downloadLink;
  useEffect(() => {
    if (downloadLink?.success && downloadLink?.downloadURL) {
      window.location.href = downloadLink.downloadURL;
    }
  }, [downloadLink]);
  if (downloadLink && downloadLink.success) {
    return (
      <Grid textAlign="center" style={{ height: '100vh', marginTop: 40 }}>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="teal" textAlign="center">
            Download File
          </Header>
          <Form size="large">
            <Segment>
              <p>
                You're download of <b>{downloadLink?.name}</b> by <b>{downloadLink?.whoShared}</b> will begin shortly
              </p>
              <p>if it does not, click this button to start the download</p>
              <Button color="teal" fluid size="large" as="a" href={downloadLink?.downloadURL}>
                Download
              </Button>
            </Segment>
            <Message>
              Powered by <a href="https://www.getsecureshare.com">Secure Share</a>
            </Message>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
  if (downloadLink && !downloadLink.success) {
    return (
      <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="teal" textAlign="center">
            Invalid Download Link
          </Header>
          <Form size="large">
            <Segment stacked>
              <p>The link is either invalid or it has already been used. Please try again.</p>
            </Segment>
            <Message>
              Powered by <a href="https://www.getsecureshare.com">Secure Share</a>
            </Message>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
  // default to loading
  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          Download File
        </Header>
        <Segment stacked>
          <Loader content={'Loading.....'} inline active={loading} size="small" />
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default CompleteDownload;
