import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { GetCurrentUserResult, GET_CURRENT_USER, User } from '../../api/graphql/queries/currentUser';
import NavBar from '../../components/navbar';
import LandingPage from '../LandingPage';
import CreateOrganisation from './containers/CreateOrganisation';
import OrganisationHome from './containers/OrganisationHome';

const MainApp = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <NavBar />
      <Routes>
        <Route path="/organisations/new" element={<CreateOrganisation />} />
        <Route path="/organisations/:organisationId/*" element={<OrganisationHome />} />
        <Route path="/" element={<LandingPage />} />
      </Routes>
    </div>
  );
};

export default MainApp;
