import { useParams } from 'react-router';

const AcceptInvite = () => {
  const inviteCode = useParams().inviteCode;
  console.log({ inviteCode });
  window.location.href = `${process.env.REACT_APP_SERVER_URL}/invitation/finalize/${inviteCode}`;
  return <div>Processing your invitation...</div>;
};

export default AcceptInvite;
