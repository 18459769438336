import { gql } from '@apollo/client';

/** A request to create a new file entry */
export type CreateUploadInput = {
  /** The name of the file */
  name: string;
  /** the content type of the file */
  contentType: string;
};

/** A response to a file upload request */
export type CreateUploadResponse = {
  createUploadRequest: {
    /** Did it work? */
    success: boolean;
    /** Any relevant messages */
    message: string;
    /** The bucket the file is going to be uploaded to */
    bucket: string;
    /** The key the file is going to be uploaded to */
    key: string;
    /** Signed upload URL to upload the file */
    uploadUrl: string;
  };
};

export const CREATE_UPLOAD_REQUEST = gql`
  mutation createUploadRequest($name: String!, $contentType: String!) {
    createUploadRequest(input: { name: $name, contentType: $contentType }) {
      success
      message
      bucket
      key
      uploadUrl
    }
  }
`;
