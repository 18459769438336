import { useQuery } from '@apollo/client';
import { Link, matchPath, useNavigate, useLocation } from 'react-router-dom';

import { Menu, Image, Loader, Dropdown } from 'semantic-ui-react';
import { GetCurrentUserResult, GET_CURRENT_USER, User } from '../api/graphql/queries/currentUser';
import logo from '../assets/images/logo.svg';
import LoginButton from './auth/LoginButton';
import LogoutButton from './auth/LogoutButton';
import SignupButton from './auth/SignupButton';

const NavBar = () => {
  const navigate = useNavigate();
  const { data, loading } = useQuery<GetCurrentUserResult>(GET_CURRENT_USER);
  const user = data?.currentUser;

  const showLogout = !loading && user;
  const showLogin = !loading && !user;
  const showSignup = !loading && !user;

  const { pathname } = useLocation();

  const organisationId = matchPath('/organisations/:organisationId/*', pathname)?.params?.organisationId;

  const currentOrganisation =
    user?.organisations.find((o) => o.organisation.id === organisationId) || user?.organisations[0];

  const switchOrg = (orgId: string) => {
    localStorage.setItem('organisationId', orgId);
    navigate(`/organisations/${orgId}/files/new`);
  };

  console.log(pathname);

  return (
    <Menu fixed="top" inverted>
      <Link to="/">
        <Menu.Item header>
          <Image size="mini" src={logo} style={{ marginRight: '1.5em' }} />
          Secure Share
        </Menu.Item>
      </Link>
      {user?.organisations ? (
        <>
          <Dropdown item text={currentOrganisation?.organisation?.name || 'Organisations'}>
            <Dropdown.Menu>
              {user?.organisations.map((org) => (
                <Dropdown.Item onClick={() => switchOrg(org.organisation.id)} key={org.organisation.id}>
                  {org.organisation.name}
                </Dropdown.Item>
              ))}
              <Dropdown.Item as={Link} to="/organisations/new">
                New...
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item
            active={pathname.includes('files')}
            as={Link}
            to={`/organisations/${currentOrganisation?.organisation.id}/files/new`}
          >
            Shared By Me
          </Menu.Item>
          <Menu.Item
            as={Link}
            active={pathname.includes('shared_with_me')}
            to={`/organisations/${currentOrganisation?.organisation.id}/shared_with_me`}
          >
            Shared With Me
          </Menu.Item>
          <Menu.Item
            as={Link}
            active={pathname.includes('settings')}
            to={`/organisations/${currentOrganisation?.organisation.id}/settings`}
          >
            Settings
          </Menu.Item>
        </>
      ) : null}
      <Menu.Menu position="right">
        {loading && (
          <Menu.Item>
            <Loader active={loading} />
          </Menu.Item>
        )}
        {showLogout && <LogoutButton user={user!} />}
        {showLogin && <LoginButton />}
        {showSignup && <SignupButton />}
      </Menu.Menu>
    </Menu>
  );
};

export default NavBar;
