import { useState } from 'react';
import { FormField, Form, Button, Modal, Progress, Segment, Header, Checkbox } from 'semantic-ui-react';
import FileDetails from './components/FileDetails';
import FileDrop from './components/FileDrop';
import { useMutation } from '@apollo/client';
import {
  CreateUploadInput,
  CreateUploadResponse,
  CREATE_UPLOAD_REQUEST,
} from '../../../../../../api/graphql/mutations/createUploadRequest';
import {
  CompleteUploadInput,
  CompleteUploadResponse,
  COMPLETE_UPLOAD,
} from '../../../../../../api/graphql/mutations/completeUpload';
import { Organisation } from '../../../../../../api/graphql/queries/organisation';
import { useNavigate } from 'react-router-dom';
import { GET_FILES } from '../../../../../../api/graphql/queries/file';

const AddNewFile = ({ organisation }: { organisation: Organisation }) => {
  const [currentFile, setCurrentFile] = useState<File>();
  const [newName, setNewName] = useState<string | undefined>();
  const [emailAddresses, setEmailAddresses] = useState<string[]>([]);
  const [shouldSendEmail, setShouldSendEmail] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const navigate = useNavigate();

  // mutation to complete the file upload - this is called after the file is uploaded
  const [completeFileUpload] = useMutation<CompleteUploadResponse, CompleteUploadInput>(COMPLETE_UPLOAD, {
    refetchQueries: [{ query: GET_FILES, variables: { organisationId: organisation.id } }],
    onCompleted: (response: CompleteUploadResponse) => {
      // redirect to the file details page
      setIsUploading(false);
      navigate(`/organisations/${organisation.id}/files/${response.completeFileUpload.fileId}`);
    },
  });

  // mutation to create a new upload request - this gives us the signed upload URL for the file
  const [createFileUploadRequest] = useMutation<CreateUploadResponse, CreateUploadInput>(CREATE_UPLOAD_REQUEST, {
    onCompleted: (response: CreateUploadResponse) => {
      // upload the file using XMLHTTPRequest so that we get progress updates
      const request = new XMLHttpRequest();
      request.open('PUT', response.createUploadRequest.uploadUrl);
      // upload progress event
      request.upload.addEventListener('progress', function (e) {
        // upload progress as percentage
        setProgress((100 * e.loaded) / e.total);
      });
      // upload finished event
      request.addEventListener('load', function () {
        console.log('Completing upload with', {
          bucket: response.createUploadRequest.bucket,
          key: response.createUploadRequest.key,
        });
        // complete the upload with the backend
        completeFileUpload({
          variables: {
            name: newName || currentFile!.name,
            contentType: currentFile!.type,
            bucket: response.createUploadRequest.bucket,
            key: response.createUploadRequest.key,
            authorisedEmails: emailAddresses,
            sendEmail: shouldSendEmail,
            organisationId: organisation.id,
          },
        });
      });
      request.addEventListener('error', function (e) {
        // TODO - let the user know that the upload failed
        console.log('error', e);
        setIsUploading(false);
      });
      // send the file
      request.send(currentFile);
    },
  });

  // kick off the file upload process
  const upload = () => {
    setIsUploading(true);
    createFileUploadRequest({
      variables: {
        name: newName || currentFile!.name,
        contentType: currentFile!.type,
      },
    });
  };

  return (
    <>
      <Header as="h2">Share a new file</Header>
      <FileDrop currentFile={currentFile} setCurrentFile={setCurrentFile} />
      {currentFile && (
        <>
          <Segment>
            <FileDetails
              currentFile={currentFile}
              newName={newName}
              setNewName={setNewName}
              emailAddresses={emailAddresses}
              setEmailAddresses={setEmailAddresses}
            />
          </Segment>
          <Segment>
            <Form>
              <FormField>
                <label>Automatically email the recipients?</label>
                <Checkbox toggle checked={shouldSendEmail} onChange={() => setShouldSendEmail(!shouldSendEmail)} />
              </FormField>
              <FormField inline>
                <label>Press upload when you are ready</label>
                <Button onClick={() => upload()}>Upload</Button>
              </FormField>
            </Form>
          </Segment>
        </>
      )}
      <Modal centered={false} open={isUploading}>
        <Modal.Header>Uploading</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            Your file is being uploaded, please do not close this window until it has finished
            <Progress percent={progress} indicating />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default AddNewFile;
