import { useQuery } from '@apollo/client';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { GetCurrentUserResult, GET_CURRENT_USER, User } from '../../../../api/graphql/queries/currentUser';
import { GetOrganisationResult, GET_ORGANISATION } from '../../../../api/graphql/queries/organisation';
import AddNewFile from './components/AddNewFile';
import FileDetails from './components/FileDetails';
import MyFiles from './components/MyFiles';
import OrganisationSettings from './components/OrganisationSettings';
import SharedWithMe from './components/SharedWithMe';

const OrganisationHome = () => {
  const navigate = useNavigate();
  // get the current user
  const { data: currentUserResult, loading: userLoading } = useQuery<GetCurrentUserResult>(GET_CURRENT_USER);
  // get the organisation from the url
  const { organisationId } = useParams();
  const { data: organisationData, loading: organisationLoading } = useQuery<GetOrganisationResult>(GET_ORGANISATION, {
    variables: { organisationId },
  });
  if (userLoading || organisationLoading) {
    return <div>Loading...</div>;
  }
  // everything finished loaded - sanity check
  const user = currentUserResult?.currentUser;
  if (!user) {
    // something has gone wrong - the user is not valid - log them out
    window.location.href = `${process.env.REACT_APP_SERVER_URL}/logout`;
    return <div>Logging you out</div>;
  }
  const organisation = organisationData?.organisation;
  if (!organisation) {
    // didn't find the organisation send the user home
    navigate('/');
    return <div>Organisation not found</div>;
  }
  return (
    <Routes>
      <Route path="shared_with_me/*" element={<SharedWithMe />} />
      <Route path="files/*" element={<MyFiles organisation={organisation} />} />
      <Route path="settings/*" element={<OrganisationSettings organisation={organisation} currentUser={user} />} />
    </Routes>
  );
};

export default OrganisationHome;
