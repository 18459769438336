import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, Grid, Header, Loader, Message, Segment } from 'semantic-ui-react';
import {
  CreateDownloadInput,
  CreateDownloadResponse,
  CREATE_DOWNLOAD_REQUEST,
} from '../../api/graphql/mutations/createDownloadRequest';
import { GetAnymouseFileInfoResult, GET_ANONYMOUS_FILE_INFO } from '../../api/graphql/queries/file';

const RequestDownload = () => {
  const [isBusy, setIsBusy] = useState(false);
  const [email, setEmail] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [success, setSuccess] = useState(false);
  const fileId = useParams().fileId!;
  const { data, loading } = useQuery<GetAnymouseFileInfoResult>(GET_ANONYMOUS_FILE_INFO, {
    variables: { fileId },
  });
  // mutation to confirm email address and trigger an email with the download link
  const [createDownloadRequest] = useMutation<CreateDownloadResponse, CreateDownloadInput>(CREATE_DOWNLOAD_REQUEST, {
    onCompleted: (response: CreateDownloadResponse) => {
      setIsBusy(false);
      if (response.createDownloadRequest.success) {
        setSuccess(true);
      } else {
        setMessage(response.createDownloadRequest.message);
      }
    },
  });

  const confirmEmail = () => {
    if (email && email.length > 0) {
      setIsBusy(true);
      createDownloadRequest({ variables: { fileId, email } });
    }
  };

  const fileInfo = data?.file;

  if (success) {
    return (
      <Grid textAlign="center" style={{ height: '100vh' }}>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="teal" textAlign="center">
            Success
          </Header>
          <Segment>
            <p>You should shortly receive an email with the download link</p>
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }

  if (loading || isBusy) {
    return (
      <Grid textAlign="center" style={{ height: '100vh' }}>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="teal" textAlign="center">
            Download File
          </Header>
          <Segment>
            <Loader content={'Working.....'} inline active={loading} size="small" />
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
  return (
    <Grid textAlign="center" style={{ height: '100vh' }}>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          Download File
        </Header>
        <Form size="large">
          <Segment>
            <p>
              You have been invited to download the file <b>{fileInfo?.name}</b> by <b>{fileInfo?.uploader}</b>
            </p>
            <p>Please enter your email address to confirm your identity:</p>
            <Form.Input
              fluid
              icon="email"
              iconPosition="left"
              placeholder="E-mail address"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <Button color="teal" fluid size="large" onClick={confirmEmail}>
              Confirm Email
            </Button>
          </Segment>
          {message && <p>{message}</p>}
          <Message>
            Powered by <a href="https://www.getsecureshare.com">Secure Share</a>
          </Message>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default RequestDownload;
