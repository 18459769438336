import { Route, Routes } from 'react-router';
import { Grid } from 'semantic-ui-react';
import { Organisation } from '../../../../../api/graphql/queries/organisation';
import AddNewFile from './AddNewFile';
import FileDetails from './FileDetails';
import FileList from './FileList';

const MyFiles = ({ organisation }: { organisation: Organisation }) => {
  return (
    <Grid style={{ flex: 1 }} divided>
      <Grid.Column width={3} style={{ paddingLeft: 40, paddingRight: 20, paddingTop: 60 }}>
        <FileList tab="sharedby" />
      </Grid.Column>
      <Grid.Column width={9} style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 60 }}>
        <Routes>
          <Route path="new" element={<AddNewFile organisation={organisation} />} />
          <Route path=":fileId" element={<FileDetails />} />
        </Routes>
      </Grid.Column>
    </Grid>
  );
};

export default MyFiles;
