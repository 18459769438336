import { gql } from '@apollo/client';

export type DownloadLink = {
  // The person who shared the file
  whoShared: string;
  // The name of the file
  name: string;
  // The URL to download the file
  downloadURL?: string;
  // A message if an error occurred
  message?: string;
  // Status (success or failure)
  success: boolean;
};

export type GetDownloadLinkResult = {
  success: boolean;
  message?: string;
  downloadLink: DownloadLink;
};

export const GET_DOWNLOAD_LINK = gql`
  query GetDownloadLink($downloadId: ID!) {
    downloadLink(id: $downloadId) {
      downloadURL
      message
      success
    }
  }
`;
