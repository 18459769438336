import { gql } from '@apollo/client';

export type RemoveUserFromOrganisationInput = {
  organisationId: string;
  userIds: string[];
};

export type RemoveUserFromOrganisationResponse = {
  removeUsersFromOrganisation: {
    success: boolean;
    message: string;
  };
};

export const REMOVE_USERS_FROM_ORGANISATION = gql`
  mutation removeUsersFromOrganisation($organisationId: ID!, $userIds: [ID]!) {
    removeUsersFromOrganisation(input: { organisationId: $organisationId, userIds: $userIds }) {
      success
      message
    }
  }
`;
