import { gql } from '@apollo/client';

export type InviteUsersInput = {
  organisationId: string;
  owners: string[];
  members: string[];
};

export type InviteUsersResult = {
  inviteUsersToOrganisation: {
    success: boolean;
    failureMessage: [string];
  };
};

export const INVITE_USERS_TO_ORGANISATION = gql`
  mutation inviteUsersToOrganisation($organisationId: ID!, $owners: [String]!, $members: [String]!) {
    inviteUsersToOrganisation(input: { organisationId: $organisationId, members: $members, owners: $owners }) {
      success
      failureMessage
    }
  }
`;
