import { gql } from '@apollo/client';
import { OrganisationRole } from './types';

export type User = {
  /** The id of the user */
  id: string;
  /** The name of the user */
  name: string;
  /** Profile image */
  picture: string;
  /** the organisations the user has access to **/
  organisations: Array<{ role: OrganisationRole; organisation: { id: string; name: string } }>;
};

export type GetCurrentUserResult = {
  currentUser: User;
};

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      name
      picture
      organisations {
        role
        organisation {
          id
          name
        }
      }
    }
  }
`;
