import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container } from 'semantic-ui-react';
import { GetCurrentUserResult, GET_CURRENT_USER, User } from '../../api/graphql/queries/currentUser';

function getDefaultOrganisation(user: User | undefined) {
  if (user) {
    // does the user have any organisations?
    if (user.organisations.length > 0) {
      // which organisation should we default to? Check to see if there is one in local storage
      const organisation = user.organisations.find((o) => o.organisation.id === localStorage.getItem('organisationId'));
      if (organisation) {
        return organisation.organisation;
      } else {
        // if not, default to the first one
        return user.organisations[0].organisation;
      }
    }
  }
  return undefined;
}

const LandingPage = () => {
  const navigate = useNavigate();
  const { data, loading } = useQuery<GetCurrentUserResult>(GET_CURRENT_USER);
  const user = data?.currentUser;
  const organisation = getDefaultOrganisation(user);
  useEffect(() => {
    if (user) {
      if (organisation) {
        // user has an organisation - send them to the organisation page
        navigate(`/organisations/${organisation.id}/files/new`);
      } else {
        // user has no organisations - send them to the create organisation page
        navigate('/organisations/new');
      }
    } else {
      // go to the login page
      navigate('/');
    }
  }, [user, organisation, history]);

  if (loading || user) {
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: 100 }}>
      <Container>
        <h1>Welcome to Secure Share</h1>
        <p>Loading...</p>
      </Container>
    </div>;
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: 100 }}>
      <Container>
        <h1>Welcome to Secure Share</h1>
        <p>Please log in or sign up</p>
        <Button primary href={`${process.env.REACT_APP_SERVER_URL}/login`}>
          Login
        </Button>
        <Button primary href={`${process.env.REACT_APP_SERVER_URL}/signup`}>
          Signup
        </Button>
      </Container>
    </div>
  );
};

export default LandingPage;
