import { Route, Routes } from 'react-router';
import { Grid } from 'semantic-ui-react';
import DirectDownload from './DirectDownload';
import FileList from './FileList';

const SharedWithMe = () => {
  return (
    <Grid style={{ flex: 1 }} divided>
      <Grid.Column width={3} style={{ paddingLeft: 40, paddingRight: 20, paddingTop: 60 }}>
        <FileList tab="sharedwith" />
      </Grid.Column>
      <Grid.Column width={9} style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 60 }}>
        <Routes>
          <Route path="" element={<p>Pick a file</p>} />
          <Route path=":fileId" element={<DirectDownload />} />
        </Routes>
      </Grid.Column>
    </Grid>
  );
};

export default SharedWithMe;
