import { Link } from 'react-router-dom';
import { Grid, Tab, Header, Container, Breadcrumb } from 'semantic-ui-react';
import { User } from '../../../../../../api/graphql/queries/currentUser';
import { Organisation } from '../../../../../../api/graphql/queries/organisation';
import Billing from './components/Billing';
import Members from './components/Members';

const OrganisationSettings = ({ organisation, currentUser }: { organisation: Organisation; currentUser: User }) => {
  const panes = [
    {
      menuItem: 'Billing',
      render: () => (
        <Tab.Pane>
          <Billing />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Members',
      render: () => (
        <Tab.Pane>
          <Members organisation={organisation} currentUser={currentUser} />
        </Tab.Pane>
      ),
    },
  ];

  const sections = [
    { key: 'Home', content: 'Home', href: '/', link: true },
    { key: 'Organisation', content: organisation.name, href: `/organisations/${organisation.id}`, link: true },
    { key: 'Settings', content: 'Settings', active: true },
  ];

  return (
    <Container style={{ paddingTop: 80 }}>
      <Breadcrumb icon="right angle" sections={sections} />
      <Tab menu={{ fluid: true, vertical: true, tabular: true }} panes={panes} />
    </Container>
  );
};

export default OrganisationSettings;
