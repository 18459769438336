import { gql } from '@apollo/client';
import { OrganisationRole } from './types';

type OrganisationUser = {
  role: OrganisationRole;
  userId: string;
  name: string;
};

export type Organisation = {
  id: string;
  /** The name of the organisation */
  name: string;
  users: OrganisationUser[];
};

export type GetOrganisationResult = {
  organisation: Organisation;
};

export const GET_ORGANISATION = gql`
  query GetOrganisation($organisationId: ID!) {
    organisation(id: $organisationId) {
      id
      name
      users {
        role
        userId
        name
      }
    }
  }
`;
