import { useQuery } from '@apollo/client';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { Header, List } from 'semantic-ui-react';
import { Tab } from 'semantic-ui-react';

import { GET_FILES, IGetFilesResponse } from '../../../../../../../api/graphql/queries/file';

const SharedByMe = () => {
  const { pathname } = useLocation();

  const { fileId, organisationId } = matchPath('/organisations/:organisationId/files/:fileId', pathname)?.params || {};

  const { data, loading } = useQuery<IGetFilesResponse>(GET_FILES, {
    variables: {
      organisationId,
    },
  });
  if (loading || !data) {
    return <p>Loading...</p>;
  }
  return (
    <>
      {data.files.length === 0 && <p>You have not shared any files.</p>}
      <List divided>
        {data.files.map((file) => (
          <List.Item
            style={{ padding: 10, backgroundColor: file.id == fileId ? '#F5F5F5' : 'inherit' }}
            as={Link}
            to={`/organisations/${organisationId}/files/${file.id}`}
            key={file.id}
          >
            <List.Icon name="file" />
            <List.Content>
              <List.Header>{file.name}</List.Header>
            </List.Content>
          </List.Item>
        ))}
      </List>
    </>
  );
};

export default SharedByMe;
